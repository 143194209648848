.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  }
  
  .modal-content {
    background-image: linear-gradient(to bottom right, #3C36D9, #9133A6, #BF265E);
    margin: 15% auto;
    padding: 20px;
    border: 4px solid #ffffff;
    border-radius: 25px;
    width: 30%;
  }
  
  .loading-animation {
    width: 50px;
    height: 50px;
    border: 5px solid #ffffff;
    border-top-color: #1d64a7;
    border-radius: 50%;
    margin: 20px auto;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .close {
    float: right;
    font-size: 28px;
    font-weight: bold;
    cursor: pointer;
  }
  
  .close:hover,
  .close:focus {
    color: #161E46;
    text-decoration: none;
    cursor: pointer;
  }