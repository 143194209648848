.App {
  font-family: Arial, sans-serif;
  text-align: center;
  padding: 20px;
  color: #ffffff;

}

html, body {
  height: 100%;
  margin: 0;
}

body {
  background-image: linear-gradient(to bottom right, #3C36D9, #9133A6, #BF265E);
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  padding: 25px;
}


form {
  margin-bottom: 20px;
}

.QuizQuestion {
  display: flex;
  flex-direction: column;
  align-items: stretch; /* Add this line */
  margin-bottom: 25px;
  border: 4px solid #ffffff;
  border-radius: 25px;
  padding: 25px;
  background-color: #161E4680;
  width: 100%;
}

h3 {
  margin-bottom: 10px;
  margin-top: 0px;
  text-align: center;
  font-size: 24px;
}

label {
  display: block;
  text-align: left;
  margin-bottom: 5px;
  cursor: pointer;
  font-size: 20px;
}

input {
  border-radius: 25px;
  height: 30px;
  width: 300px;
  font-size: large;
  padding-left: 10px;
  padding-right: 10px;
}

@media (max-width: 330px) {
  input {
    width: 95%;
  }
}

input[type='radio'] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
  border: none;
  display: none;
}

button {
  background-color: #21D2CF;
  border: none;
  color:#ffffff;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 10px 2px;
  cursor: pointer;
  border-radius: 25px;
  border: 3px solid #ffffff;
}

button:hover {
  background-color: #9133A6;
}

.Quiz {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
}

.correctAnswer {
  background-color: #21D2CF;
  border: 4px solid #21D2CF;
  font-weight: bold;
  border-radius: 45px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  
}

.incorrectAnswer {
  font-weight: bold;
  background-color: #BF265E;
  border-radius: 45px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  border: 4px solid #BF265E;
}

.radioInput {
  font-weight: bold;
  border-radius: 25px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  border: 4px solid #ffffff;
}

.sourceRadioInput {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 16px;
}

.leftButton {
  border-radius: 45px 0px 0px 45px;
  max-width: 140px;
  flex: 1;
  text-align: center;
  border-left: 3px solid #ffffff;

}

.middleButton {
  border: 3px solid #ffffff;
}

.rightButton {
  border-radius: 0px 45px 45px 0px;
  max-width: 140px;
  flex: 1;
  text-align: center;
  border-right: 3px solid #ffffff;
}

.sourceRadioInputUnselected {
  border-top: 3px solid #ffffff;
  border-bottom: 3px solid #ffffff;
  font-size: 16px;
}

.sourceRadioInputSelected {
  border: 3px solid #21D2CF;
  background-color: #21D2CF;
}

.userChoice {
  font-weight: bold;
  border-radius: 45px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  border: 4px solid #ffffff;
  background-color: #21D2CF;
}

.urlDiv, .inputDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
}

.sourceTypeDiv {
  display: flex;
  justify-content: center;
  align-items: stretch;
}

textarea {
  width: 600px;
  height: 200px;
  border-radius: 25px;
  padding: 10px;
  font-size: large;
  color: #161E46;
}
@media (max-width: 625px) {
  textarea {
    width: 95%;
  }
}